import anime from 'animejs';
import { graphql, Link, navigate } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { useCallback } from 'react';
import { useEffect, useState } from 'react';
import AnimeLink from '../components/AnimeLink';
import Head from '../components/Head';
import Header from '../components/Header';

const WorksPage: React.VFC<{
  data: any;
  location: Location;
}> = ({ data, location }) => {
  const [works] = useState(data.allMarkdownRemark.nodes);

  useEffect(() => {}, []);

  return (
    <>
      <Head title="Works" description="totorajの作ったものリスト" />
      <main className="body">
        <h1>Works</h1>
        <ul className="works-list">
          {works &&
            works
              .filter((work) => !work.frontmatter.hidden)
              .map((work) => {
                const image = work.frontmatter.thumbnail
                  ? work.frontmatter.thumbnail.publicURL
                  : null;
                // console.log(image);
                return (
                  <li key={work.fields.slug}>
                    <AnimeLink to={'/works' + work.fields.slug}>
                      {image && (
                        <div
                          className="thumbnail"
                          style={{
                            backgroundImage: `url(${image})`,
                          }}
                        ></div>
                      )}
                      {!image && (
                        <div className="thumbnail">
                          <p>{work.frontmatter.title}</p>
                        </div>
                      )}
                      <p className="title">{work.frontmatter.title}</p>
                    </AnimeLink>
                    {/* <AnimeLink to={`/works${work.fields.slug}`}>
                      {work.frontmatter.title}
                    </AnimeLink> */}
                  </li>
                );
              })}
        </ul>
      </main>
    </>
  );
};

export default WorksPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date
          title
          description
          hidden
          thumbnail {
            publicURL
          }
        }
      }
    }
  }
`;
